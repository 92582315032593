<template>
<div class="account-content">
		
     <content-loader  v-if="loaded==false"
          viewBox="0 0 520 230"
          primaryColor="#f3f3f3"
          secondaryColor="#cccccc"
          >

          <rect x="0" y="20" rx="5" ry="5" width="520" height="10" />

          <rect x="0" y="60" rx="5" ry="5" width="520" height="10" />

      </content-loader>

		<div id="error">{{error}}</div>
		<div id="content-cards" v-bind:class="{active:displayCard}" >
		
			<div id="form-add-card" class="subcategory-account" v-show="loaded" style="display:block;">
				<form id="new_card_form" class="login-form">
					<div class="form-row">
						<label>Ajouter une carte :</label>
						<div id="card-element" class="form-control"></div>
					</div>
					<br /><br />
					<button id="add_card" @click="addCard()" type="submit">Ajouter une carte</button>
				</form>

				<div id="card-errors" class="help-block" role="alert"></div>
			</div>
		</div>
			
	</div>
</template>

<script>

import Footer from '../../components/layout/Footer.vue'
import jQuery  from 'jquery';
import {Auth, APIRequest} from "@simb2s/senseye-sdk"
import Stripe from 'stripe';
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'AddCard',
  components: {
    Footer,
    ContentLoader
  },
  data() {
    return {
      user_id:null,
      user:null,
      loaded:false,
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      stripe: '',
      elements: '',
      card: '',
      customer_stripe_id:'',
      displayCard:false,
      displayLogin:false
    }
  },
  methods:{
    async addCard()
    {
      this.loaded=false
      this.stripe.createToken(this.card).then(function(result) {
        if (result.error) {
          this.error=result.error.message
          errors.textContent = result.error.message;
          form.classList.add('has-error');
          this.loaded=true
          intiated=false;

        } else {
          this.loaded=true
          result.token.id;
          Auth.add_card(this.customer_stripe_id, result.token.id, this.user_id)
        }
      });
    },
  },
  async mounted()
  {
  
    this.loaded=false
    this.user_id=this.$route.params.id;
    let user=await Auth.get_user_with_id(this.user_id)
    this.displayCard=true
    this.customer_stripe_id=user.customer_stripe_id;
    this.stripe = Stripe( this.stripeAPIToken );
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card');
    this.card.mount('#card-element');
    this.loaded=true

  }
  
}
</script>
<style scoped>
#content-cards
{
  display:none;
}
#content-cards.active
{
  display: block;
}
</style>